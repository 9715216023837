
import { Component, Vue } from "vue-property-decorator";

import GroupsTable from "@/components/Core/Table/Localisation/GroupsTable.vue";

@Component({
  components: {
    GroupsTable,
  },
})
export default class Groups extends Vue {}
