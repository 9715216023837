
import { Component, Inject, Vue } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import TableUi from "@/components/Core/Table/TableUI.vue";

import { NuclearUnitInfos, ServerType } from "prometheus-synced-ui";
import {
  HydraulicSystemGroupDetails,
  HydraulicSystemGroupInfos,
  LITE_FEATURE,
} from "@/domain";

import { IHydraulicSystemGroupService } from "@/services";

@Component({
  components: {
    TableUi,
  },
})
export default class GroupsTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  get hydraulicSystemGroupService() {
    return this.container.resolve<IHydraulicSystemGroupService>(
      S.HYDRAULIC_SYSTEM_GROUP_SERVICE
    );
  }

  get headers() {
    return [
      {
        text: "Nom",
        align: "center",
        sortable: true,
        value: "name",
      },
      {
        text: "Monogramme",
        align: "center",
        sortable: true,
        value: "monogram",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
        align: "center",
        visibility: (serverType: ServerType) =>
          LITE_FEATURE.visibility(serverType),
      },
    ];
  }

  get createComponent() {
    return () =>
      import("@/components/Core/Create/Localisation/GroupCreate.vue");
  }

  get updateComponent() {
    return () =>
      import("@/components/Core/Update/Localisation/GroupUpdate.vue");
  }

  get existingNameMonograms() {
    return _.map(this.groups, (g) => {
      return `${g.name.toUpperCase()}-${g.monogram.toUpperCase()}`;
    });
  }

  loading: boolean = false;
  groups: HydraulicSystemGroupInfos[] = [];
  nuclearUnits: NuclearUnitInfos[] = [];
  nuclearUnitId: string | null = null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    this.loading = true;
    try {
      this.groups = await this.hydraulicSystemGroupService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  create(group: HydraulicSystemGroupDetails) {
    this.groups.push(HydraulicSystemGroupInfos.fromDetails(group));
  }

  update(group: HydraulicSystemGroupDetails) {
    const index = _.findIndex(this.groups, (s) => s.id == group.id);
    if (index != -1) {
      this.groups.splice(
        index,
        1,
        HydraulicSystemGroupInfos.fromDetails(group)
      );
    }
  }

  async updateDisabled(id: string) {
    try {
      this.loading = true;
      const group = _.find(this.groups, (g) => g.id == id)!;
      const result = await this.hydraulicSystemGroupService.update(id, {
        name: group.name!,
        monogram: group.monogram!,
        disabled: !group.disabled,
      });
      this.update(result);
    } finally {
      this.loading = false;
    }
  }
}
